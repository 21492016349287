<template>
<!-- 客户管理 -->
  <div class="box100">
    <div class="whiteBg" v-if="!showDetail">
<!--      <template v-if="roleBtn[2].power">-->
<!--        <div class="el-icon-download settingExport"><span> 导出</span></div>-->
<!--      </template>-->
      <div class="flexAC" style="margin: 16px 0px;">
        <el-input v-model="keyWord"  placeholder="请输入账号/手机号/账号名称" class="inputSearch"></el-input>
        <el-button class="btnSearch" @click="handleSearch">搜索</el-button>
        <el-button class="btnReset" @click="handleReset">重置</el-button>
      </div>
      <div class="table_list">
        <el-table
          :data="customList"
          :header-cell-style="{background:'#EBF2FE'}">
          <el-table-column
            label="账号名称"
            prop="username">
          </el-table-column>
          <el-table-column
            label="手机号"
            prop="uphone">
          </el-table-column>
          <el-table-column
            label="账号积分"
            prop="points">
          </el-table-column>
          <el-table-column
            label="账号余额"
            prop="balance">
          </el-table-column>
          <el-table-column
            label="卡券数量"
            prop="coupont">
          </el-table-column>
          <el-table-column
            label="客户单价"
            prop="univalence">
          </el-table-column>
          <el-table-column
            label="平台消费总额"
            prop="rental">
          </el-table-column>
          <el-table-column
            label="上次消费时间"
            prop="upextime"
            show-overflow-tooltip>
          </el-table-column>
          <el-table-column
            label="上次服务门店"
            prop="shopname"
            show-overflow-tooltip>
          </el-table-column>
          <el-table-column
            label="活跃度"
            prop="vitality">
          </el-table-column>
          <el-table-column
          label="操作"
          width="200">
          <template slot-scope="scope">
            <template v-if="roleBtn[0].power">
              <el-button type="text" style="color:#3C84EF" size="small" @click="handDetail(scope.row)">查看详情</el-button>
            </template>
            <template v-if="roleBtn[1].power">
              <el-button type="text" style="color:#3C84EF" size="small" @click="handUpload(scope.row)">上传资料</el-button>
            </template>
          </template>
        </el-table-column>
        </el-table>

        <el-pagination
          @current-change="pageChange"
          :current-page="page"
          :page-sizes="[10]"
          :page-size="size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          class="settingPage">
        </el-pagination>
      </div>
    </div>

    <!-- 详情 -->
    <div class="detailForm" v-if="showDetail">
      <CoutomerDetail :detailRow="detailRow" @handBack="handBack"></CoutomerDetail>
    </div>

    <!--  上传资料-弹框  -->
    <el-dialog title="新增" :visible.sync="formShow" width="600px" center class="gyDialog" :close-on-click-modal="false" :before-close="formCancel">
      <el-form :model="accountFrom" :rules="accountRule" ref="accountInfo">
        <el-form-item class="fiLabelLeft" label="客户照片" prop="udphoto">
          <Upload :fileType="'jpg/png/jpeg'" :picture="accountFrom.udphoto_furl" @uploadSuccess="infoImgSuccess"></Upload>
        </el-form-item>
        <el-form-item class="fiLabelLeft" label="合同附件" prop="udpact">
          <el-upload ref="upload"
                     class="upload-demo"
                     accept=".jpg,.PNG,.JPG,.JPEG,.png,.jpeg,.xls,.xlsx,.docx,.doc,.pdf,.html"
                     :action="action"
                     :headers="headers"
                     :file-list="fileList"
                     :before-upload="beforeAvatarUpload"
                     :on-remove="removeFiles"
                     :on-success="handleSuccess">
            <div><i class="el-icon-upload2"></i>上传文件</div>
            <div slot="tip" class="el-upload__tip">
              <div></div>
              <div>{{ addFileName }}</div>
            </div>
          </el-upload>
        </el-form-item>
      </el-form>

      <div class="flexCE">
        <div class="settingCancel" @click="formCancel">取消</div>
        <div class="settingConfirm" @click="FromConfirm">确定</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Upload from '@/components/upload.vue'
import CoutomerDetail from '@/components/customer/customerDetail'
import { customerQuery, customerUpAdd, customerUpQuery } from '@/api/customer'
export default {
  components: {
    CoutomerDetail,
    Upload
  },
  props: ['roleBtn'],
  data () {
    return {
      page: 1,
      size: 10,
      total: 0,
      keyWord: '',
      customList: [],
      showDetail: false,
      detailRow: '',
      formShow: false,
      accountFrom: {
        userid: '',
        udphoto: '',
        udpact: []
      },
      accountRule: {
        udphoto: { required: true, trigger: 'blur', message: '请上传客户照片' },
        udpact: { required: true, trigger: 'blur', message: '请上传客户合同照片' }
      },
      action: this.BASE_URL + this.$url.PATH.annex,
      fileList: [],
      addFileName: '',
      haveUp: false
    }
  },
  computed: {
    headers () {
      return {
        Authorization: window.localStorage.getItem('token'),
        loginway: 'pc',
        sign: this.$url.PATH.sign
      }
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      const params = {
        username: this.keyWord,
        page_index: this.page,
        page_size: this.size
      }
      customerQuery(params).then(res => {
        if (res.status === 1) {
          this.customList = res.data.data
          this.total = res.data.recordcount
        }
      })
    },
    handleSearch () {
      this.init()
    },
    handleReset () {
      this.keyWord = ''
      this.init()
    },
    pageChange (page) {
      this.page = page
      this.init()
    },
    // 详情
    handDetail (row) {
      this.showDetail = true
      this.detailRow = { ...row }
    },
    handBack () {
      this.showDetail = false
    },
    // 点击【上传资料】
    handUpload (row) {
      this.formShow = true
      this.accountFrom.userid = row.userid
      customerUpQuery({ userid: row.userid }).then(res => {
        if (res.status === 1) {
          if (res.data.data.length > 0) {
            const _data = res.data.data[0]
            this.accountFrom = _data
            this.fileList = _data.udpact
            this.haveUp = true
          } else {
            this.$refs.accountInfo.resetFields()
            this.accountFrom = {
              userid: row.userid,
              udphoto: '',
              udpact: []
            }
            this.fileList = []
            this.haveUp = false
          }
        }
      })
    },
    infoImgSuccess (res) {
      this.accountFrom.udphoto = res.fid
    },
    // 关闭上传
    formCancel () {
      this.formShow = false
    },
    beforeAvatarUpload (file) {
      const type = file.name.substring(file.name.lastIndexOf('.') + 1)
      const isJPG = 'xls/xlsx/docx/doc/pdf/html/jpeg/jpg/PNG/png/JPG/JPEG'.includes(type)
      const isLt50M = file.size / 1024 / 1024 < 50
      if (!isJPG) {
        this.$message.error('上传文件类型为jpeg/jpg/PNG/png/JPG/JPEG!')
        return false
      } else if (!isLt50M) {
        this.$message.error('上传附件大小不能超过 50MB!')
        return false
      } else {
        return isJPG && isLt50M
      }
    },
    // 清除图片
    removeFiles (file, fileList) {
      let _fid = ''
      if (this.haveUp === true) { // 有值时
        _fid = file.udpact
      } else {
        _fid = file.response.data.fid
      }
      this.accountFrom.udpact.forEach((item, index) => {
        if (_fid === item.udpact) {
          this.accountFrom.udpact.splice(index, 1)
        }
      })
    },
    // 添加图片
    handleSuccess (data, file) {
      const obj = {
        name: file.name,
        udpact: data.data.fid,
        furl_udpact: data.data.furl
      }
      this.accountFrom.udpact.push(obj)
    },
    // 客户资料【上传提交】
    FromConfirm () {
      console.log(this.accountFrom.userid)
      this.$refs.accountInfo.validate((valid) => {
        if (!valid) return
        const params = {
          userid: this.accountFrom.userid,
          udphoto: this.accountFrom.udphoto,
          udpact: this.accountFrom.udpact
        }
        customerUpAdd(params).then(res => {
          if (res.status === 1) {
            this.$message.success(res.message)
            this.formCancel()
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      })
    }
  }
}
</script>

<style scoped>
.detailForm{
  background: transparent;
  padding: 0px;
}
.upload-demo .el-upload > div{
  width: 105px;
  height: 32px;
  line-height: 32px;
  border-radius: 4px;
  cursor: pointer;
  background: #44b557;
  text-align: center;
  color: #FFFFFF;
}
.upload-demo .el-upload__tip {
  display: inline-block;
  margin-left: 10px;
  color: #ccc;
}
</style>
