<template>
  <div class="box100">
    <div class="box100 infoCont">
      <span class="backSpan" @click="goBack"><i class="el-icon-arrow-left"></i>返回</span>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="账号信息" name="first">
          <div class="infoBox firstBox">
            <div class="firstL">
              <p class="formTitle">基本信息</p>
              <img :src="detailRow.furl" style="width: 110px;height: 110px;border-radius: 50%;"/>
              <p class="infoP"><span>账号名称</span>{{ detailRow.username }}</p>
              <p class="infoP" v-if="detailRow.usex === 1"><span>性别</span>男</p>
              <p class="infoP" v-if="detailRow.usex === 2"><span>性别</span>女</p>
              <p class="infoP"><span>手机号码</span>{{ detailRow.uphone }}</p>
              <p class="infoP">
                <span>出生日期</span>
                <span v-if="detailRow.birthday != null" style="width: auto;color: #333;">{{ detailRow.birthday }}</span>
              </p>
              <p class="infoP"><span>注册时间</span>{{ detailRow.creatime }}</p>
            </div>
            <div class="firstR">
              <div class="formClass">
                <p class="formTitle">消费信息</p>
                <div class="flexCB infoItem" style="margin-bottom: 40px;">
                  <div><p>账户余额</p><p><span class="infoNum">{{ detailRow.balance }}</span>元</p></div>
                  <div><p>卡券数量</p><p><span class="infoNum">{{ detailRow.coupont }}</span>张</p></div>
                  <div><p>客单价</p><p><span class="infoNum">{{ detailRow.univalence }}</span>元</p></div>
                </div>
                <div class="flexCB infoItem">
                  <div><p>活跃度</p><p><span class="infoNum">{{ detailRow.vitality }}</span></p></div>
                  <div><p>积分</p><p><span class="infoNum">{{ detailRow.points }}</span>分</p></div>
                  <div><p>消费次数</p><p><span class="infoNum">{{ detailRow.excount }}</span>次</p></div>
                </div>
              </div>
              <div class="flexSC infoItem">
                <div><p>最后一次消费时间</p><p class="infoTos">{{ detailRow.upextime }}</p></div>
                <div><p>上次消费门店</p><p class="infoTos">{{ detailRow.shopname }}</p></div>
                <div></div>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="消费记录" name="second">
          <div class="infoBox secondBox">
            <el-table :data="consumeList" :header-cell-style="{background:'#EBF2FE'}">
              <el-table-column
                label="序号"
                type="index">
              </el-table-column>
              <el-table-column
                label="门店"
                prop="shopname"
                show-overflow-tooltip>
              </el-table-column>
              <el-table-column
                label="消费总额"
                prop="rental">
              </el-table-column>
              <el-table-column
                label="支付方式"
                prop="sysvalue">
              </el-table-column>
              <el-table-column
                label="消费时间"
                prop="uextime">
              </el-table-column>
              <el-table-column
                label="操作">
                <template slot-scope="scope">
                  <el-button type="text" style="color:#3C84EF" size="small" @click="lookDetail(scope.row)">查看详情</el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination
              @current-change="pageChange"
              :current-page="page"
              :page-sizes="[10]"
              :page-size="size"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
              class="settingPage">
            </el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane label="上传资料" name="third">
          <div class="infoBox secondBox">
            <div class="thirdInfo" v-if="upHave">
              <img :src="accountUpInfo.udphoto_furl"/>
              <div class="flexCB" v-for="item in accountUpInfo.udpact" :key="item.name" style="margin-top: 15px;">
                <p style="color: #3C84EF;cursor: pointer;" @click="showBigImg(item)">{{ item.name }}</p>
                <p style="color: #999;">{{ item.time }}</p>
              </div>
            </div>
            <div class="notList" v-if="!upHave" style="margin: 0;padding-top: 30%;">
              <img src="../../assets/img/noData.png"/>
              <p>暂无资料</p>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="备注记录" name="fourth">
          <div class="infoBox secondBox fourthBox">
            <div class="fourthInfo" v-if="remaHave">
              <div v-for="(item, index) in remarksList" :key="index" style="margin-top: 24px;">
                <div class="flexCB">
                  <p>{{ item.shopname }}</p>
                  <p>{{ item.creatime }}</p>
                </div>
                <p class="reP">{{ item.remark }}</p>
              </div>
            </div>
            <div v-if="!remaHave" class="notList" style="margin: 0;padding-top: 30%;">
              <img src="../../assets/img/noData.png"/>
              <p>暂无备注</p>
            </div>
            <div class="fourFrom">
              <el-input style="height: 60px;" v-model="remarksKey" type="textarea" class="formTextarea" placeholder="请输入要备注的内容（最多200字）" maxlength="200"></el-input>
              <div class="remaBtn" @click="handSend">发送</div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="套餐卡" name="fifth">
          <div class="infoBox secondBox">
            <el-table :data="cardList" :header-cell-style="{background:'#EBF2FE'}">
              <el-table-column
                label="套餐卡名称"
                prop="mcaname"
                show-overflow-tooltip>
              </el-table-column>
              <el-table-column
                label="套餐卡类型"
                prop="mcatype">
                <template slot-scope="scope">
                  <span v-if="scope.row.mcatype == 1">次卡</span>
                  <span v-if="scope.row.mcatype == 2">年卡</span>
                </template>
              </el-table-column>
              <el-table-column
                label="状态"
                prop="uacstate">
                <template slot-scope="scope">
                  <span v-if="scope.row.uacstate == 0">可使用</span>
                  <span v-if="scope.row.uacstate == 1">已使用</span>
                  <span v-if="scope.row.uacstate == 2">已过期</span>
                </template>
              </el-table-column>
              <el-table-column
                label="已使用/剩余"
                prop="remain">
              </el-table-column>
              <el-table-column
                label="有效期"
                prop="uaceffec"
                show-overflow-tooltip>
                <template slot-scope="scope">
                  <template v-if="scope.row.uacstate == 0">
                    <span v-if="scope.row.activstate == 1" style="color:#F44C8E">{{scope.row.uaceffec}}</span>
                    <span v-if="scope.row.activstate == 0">{{scope.row.uaceffec}}</span>
                  </template>
                  <template v-if="scope.row.uacstate != 0">
                    <span v-if="scope.row.activstate != 1">{{scope.row.uaceffec}}</span>
                  </template>
                </template>
              </el-table-column>
              <el-table-column
                label="购买时间"
                prop="creatime"
                show-overflow-tooltip>
              </el-table-column>
              <el-table-column
                label="操作">
                <template slot-scope="scope" v-if="scope.row.uacstate == 0">
                  <el-button type="text" style="color:#44B557" size="small" v-if="scope.row.activstate == 1" @click="changeActive(scope.row)">激活</el-button>
                  <el-button type="text" style="color:#F44C8E" size="small" v-if="scope.row.activstate == 0" @click="changeActive(scope.row)">冻结</el-button>
                </template>
              </el-table-column>
            </el-table>
            <!-- <el-pagination
              @current-change="pageChange"
              :current-page="page"
              :page-sizes="[10]"
              :page-size="size"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
              class="settingPage">
            </el-pagination> -->
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>

    <!--  消费详情  -->
    <el-dialog title="消费详情" :visible.sync="showInfo" width="1200px" center class="gyDialog" :close-on-click-modal="false" :before-close="detailCancel">
      <p class="formTitle">基本信息</p>
      <div class="flexCB">
        <p class="detailP"><span>门店</span>{{ info.shopname }}</p>
        <p class="detailP"><span>消费总额</span>{{ info.rental }}</p>
        <p class="detailP"><span>支付方式</span>{{ info.sysvalue }}</p>
        <p class="detailP"><span>消费时间</span>{{ info.uextime }}</p>
      </div>
      <p class="formTitle">服务商品</p>
      <el-table :data="consumeDetail" show-summary style="margin: 30px 0px;">
        <el-table-column
          label="名称"
          prop="servename">
        </el-table-column>
        <el-table-column
          label="数量"
          prop="scount">
        </el-table-column>
        <el-table-column
          label="价格"
          prop="srental">
        </el-table-column>
        <el-table-column
          label="服务美容师"
          prop="">
          <template slot-scope="scope" v-if="scope.row.sbecode !== null">{{ scope.row.sbecode }}</template>
        </el-table-column>
        <el-table-column
          label="手工费"
          prop="workmip">
        </el-table-column>
      </el-table>
    </el-dialog>

    <!--  合同照片  -->
    <el-dialog title="合同图片" :visible.sync="imgShow" width="600px" center class="gyDialog">
      <div style="text-align: center">
        <img :src="imgUrl" style="width: 100%;">
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { customerConsumeList, customerConsumeDetail, customerUpQuery, customerRemarkAdd, customerRemarkQu, customerCarGet, customerCarUp } from '@/api/customer'
import { downloadFile } from '../../http/http'

export default {
  name: 'customerDetail',
  props: ['detailRow'],
  data () {
    return {
      activeName: 'first',
      page: 1,
      size: 10,
      total: 0,
      consumeList: [],
      consumeDetail: [],
      info: '',
      showInfo: false,
      accountUpInfo: '',
      upHave: false,
      remarksKey: '',
      remarksList: [],
      remaHave: false,
      imgShow: false,
      imgUrl: '',
      cardList: [] //套餐卡列表
    }
  },
  created () {
  },
  methods: {
    handleClick (tab) {
      if (tab.name === 'second') {
        this.getConsumeList()
      } else if (tab.name === 'third') {
        customerUpQuery({ userid: this.detailRow.userid }).then(res => {
          if (res.status === 1) {
            if (res.data.data.length !== 0) {
              this.accountUpInfo = res.data.data[0]
              this.upHave = true
            }
          }
        })
      } else if (tab.name === 'fourth') {
        this.getInit()
      } else if (tab.name === 'fifth') {
        this.getCardList()
      }
    },
    // 消费记录
    getConsumeList () {
      const params = {
        userid: this.detailRow.userid,
        page_index: this.page,
        page_size: this.size
      }
      customerConsumeList(params).then(res => {
        if (res.status === 1) {
          this.$nextTick(() => {
            this.consumeList = res.data.data
            this.total = res.data.recordcount
          })
        }
      })
    },
    // 套餐卡
    getCardList () {
      customerCarGet({ userid: this.detailRow.userid }).then(res => {
        if (res.status === 1) {
          this.$nextTick(() => {
            this.cardList = res.data.data
          })
        }
      })
    },
    // 激活/冻结
    changeActive (row) {
      this.$confirm(`您确认${row.activstate ? "激活" : "冻结"}该套餐卡, 是否继续？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        customerCarUp({
          uacid: row.uacid,
          uacstate: row.uacstate,
          activstate: row.activstate
        }).then(res => {
          if (res.status === 1) {
            this.$message.success(res.message)
            this.getCardList()
          } else {
            this.$message.error(res.message)
          }
        })
      }).catch(() => {
      })
    },
    // 消费详情
    lookDetail (row) {
      this.showInfo = true
      this.info = { ...row }
      customerConsumeDetail({ uexid: row.uexid }).then(res => {
        if (res.status === 1) {
          this.consumeDetail = res.data.data
        }
      })
    },
    pageChange (page) {
      this.page = page
      this.getConsumeList()
    },
    detailCancel () {
      this.showInfo = false
    },
    goBack () {
      this.$emit('handBack')
    },
    // 发送备忘录
    handSend () {
      if (this.remarksKey === '') {
        this.$message.warning('请输入备注内容')
      } else {
        console.log(this.remarksKey)
        customerRemarkAdd({
          userid: this.detailRow.userid,
          remark: this.remarksKey
        }).then(res => {
          if (res.status === 1) {
            this.$message.success(res.message)
            this.getInit()
          } else {
            this.$message.error(res.message)
          }
        })
      }
    },
    // 备注查询列表
    getInit () {
      customerRemarkQu({ userid: this.detailRow.userid }).then(res => {
        if (res.status === 1) {
          if (res.data.data.length > 0) {
            this.remarksList = res.data.data
            this.remaHave = true
            this.remarksKey = ''
          }
        }
      })
    },
    showBigImg (item) {
      // this.imgShow = true
      // this.imgUrl = item.furl_udpact
      this.onDownFile(item.furl_udpact, item.name)
    },
    onDownFile (url, filename) {
      this.getBlob(url, (blob) => {
        this.saveAs(blob, filename)
      })
    },
    getBlob (url, cb) {
      var xhr = new XMLHttpRequest()
      xhr.open('GET', url, true)
      xhr.responseType = 'blob'
      xhr.onload = function () {
        if (xhr.status === 200) {
          cb(xhr.response)
        }
      }
      xhr.send()
    },
    saveAs (blob, filename) {
      if (window.navigator.msSaveOrOpenBlob) {
        navigator.msSaveBlob(blob, filename)
      } else {
        var link = document.createElement('a')
        var body = document.querySelector('body')
        link.href = window.URL.createObjectURL(blob)
        link.download = filename
        link.style.display = 'none'
        body.appendChild(link)
        link.click()
        body.removeChild(link)
        window.URL.revokeObjectURL(link.href)
      }
    }
  }
}
</script>

<style scoped>
.infoBox{
  width: 100%;
}
.infoCont >>> .el-tabs__header{
  margin-bottom: 4px;
  padding: 0px 24px;
  background: #fff;
}
.infoCont >>> .el-tabs__nav-wrap::after{
  background: transparent;
}
.infoCont >>> .el-tabs__nav{
  padding: 10px 0px;
}
.infoCont >>> .el-tabs,.infoCont >>>.el-tab-pane,.infoBox{
  width: 100%;
  height: 100%;
  position: relative;
}
.infoCont >>> .el-tabs__content{
  width: 100%;
  height: calc(100% - 55px);
  position: relative;
}
.firstBox{
  display: flex;
  align-items: start;
  justify-content: space-between;
}
.secondBox{
  background: #fff;
  position: relative;
}
.firstL{
  width: 280px;
  padding: 24px;
  background: #fff;
  box-sizing: border-box;
}
.firstR{
  width: calc(100% - 284px);
  height: 100%;
  background: #fff;
  padding: 24px;
  box-sizing: border-box;
  font-size: 14px;
}
.infoP{
  font-size: 14px;
  margin-top: 8px;
  color: #333;
}
.infoP span{
  width: 70px;
  display: inline-block;
  color: #A5B7CC;
}
.infoItem{
  color: #A5B7CC;
}
.infoItem div{
  width: 33%;
}
.infoItem p{
  margin: 4px 0px;
}
.infoNum{
  font-size: 26px;
  color: #333;
  margin-right: 5px;
  font-weight: bold;
}
.infoTos{
  margin-top: 8px;
  color: #333;
  font-weight: bold;
}
.detailP{
  width: 25%;
  margin-bottom: 30px;
}
.detailP span{
  color: #A5B7CC;
  margin-right: 10px;
}
.thirdInfo{
  text-align: center;
  padding: 40px;
  box-sizing: border-box;
  font-size: 14px;
}
.thirdInfo img{
  width: 150px;
  height: auto;
}
.fourthBox{
  padding: 40px;
  font-size: 14px;
  box-sizing: border-box;
  position: relative;
}
.fourFrom{
  width: 92%;
  position: absolute;
  bottom: 30px;
  display: flex;
  align-items: center;
}
.remaBtn{
  width: 120px;
  height: 60px;
  background: #3C84EF;
  border-radius: 4px;
  text-align: center;
  color: #fff;
  line-height: 60px;
  font-size: 14px;
}
.reP{
  margin-top: 8px;
  padding: 8px;
  box-sizing: border-box;
  background: #F7F9FB;
}
.settingPage {
  width: 95%;
}
</style>
